// eslint-disable-next-line
import { MonthDtoType } from '../../../schema/types';

export class MonthDto implements MonthDtoType {
    month: number;

    amountWithoutSell: number;

    amountWithSell: number;
}
