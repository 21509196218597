import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import type { TradeStore } from './trade-store';
import type { RootStore } from './root-store';
import { TradeExportBufferMobxDto } from '../mobx/dtos/trade/trade-export-buffer-mobx-dto';

export class TradeExportStore {
    rootStore: RootStore;

    tradeStore: TradeStore;

    loading = false;

    constructor(tradeStore: TradeStore) {
        this.rootStore = tradeStore.rootStore;
        this.tradeStore = tradeStore;

        makeAutoObservable(this, {
            rootStore: false,
            tradeStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async exportFile() {
        if (this.rootStore.authStore.userHasFreeTariff) {
            this.rootStore.premiumDialogStore.open();
        } else {
            try {
                this.startLoading();
                const response: TradeExportBufferMobxDto | null =
                    await this.rootStore.apiClient.tradeExportController.tradeExportControllerExportMonery();

                if (response && response.data && response.type === 'Buffer') {
                    const arrayBuffer = new Uint8Array(response.data);
                    const blob = new Blob([arrayBuffer]);
                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `monery-${this.rootStore.portfolioStore.selectedPortfolio.name}-${moment().format(
                        'YYYY-MM-DD',
                    )}.xlsx`;
                    link.click();
                }
            } catch (e) {
                this.rootStore.alertStore.setErrorMessage(e.message);
                // eslint-disable-next-line no-console
                console.error(e);
            } finally {
                this.stopLoading();
            }
        }
    }
}
