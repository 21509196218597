import { makeAutoObservable } from 'mobx';
import { SymbolFundamentalDataTechnicalsDto } from '../../../../../defs/api';
import { SymbolWithExchangeMobxDto } from '../symbol-with-exchange-mobx-dto';

export class SymbolDetailTechnicalsMobxDto implements SymbolFundamentalDataTechnicalsDto {
    public id: string = '';

    public symbol: SymbolWithExchangeMobxDto = new SymbolWithExchangeMobxDto();

    public beta: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    static create(dto: SymbolFundamentalDataTechnicalsDto): SymbolDetailTechnicalsMobxDto {
        const mobxDto = new SymbolDetailTechnicalsMobxDto();
        return Object.assign(mobxDto, dto, { symbol: SymbolWithExchangeMobxDto.create(dto.symbol) });
    }

    static createFromArray(dtos: SymbolFundamentalDataTechnicalsDto[]): SymbolDetailTechnicalsMobxDto[] {
        return dtos.map((dto) => SymbolDetailTechnicalsMobxDto.create(dto));
    }
}
