import { makeAutoObservable } from 'mobx';

export class ExchangeEntity {
    id: string;

    name: string;

    longName: string;

    region: string;

    suffix: string;

    constructor() {
        makeAutoObservable(this, {});
    }
}
