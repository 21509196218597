import { makeAutoObservable } from 'mobx';
import { StatusMessageEnum } from '@app/common';
import type { RootStore } from './root-store';
import { isEnumValue } from '../../utils/typeguards/is-enum-value';

export type AlertStoreHydration = {
    errorMessage: string;
    successMessage: string;
    warningMessage: string;
    loading: boolean;
};

export class AlertStore {
    rootStore: RootStore;

    errorMessage = '';

    successMessage = '';

    warningMessage = '';

    loading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    hydrate(): AlertStoreHydration {
        return {
            errorMessage: this.errorMessage,
            successMessage: this.successMessage,
            warningMessage: this.warningMessage,
            loading: this.loading,
        };
    }

    rehydrate(data: AlertStoreHydration): void {
        this.errorMessage = data.errorMessage;
        this.successMessage = data.successMessage;
        this.warningMessage = data.warningMessage;
        this.loading = data.loading;
    }

    setErrorMessage(errorMessage: string): void {
        this.errorMessage = errorMessage;
    }

    setSuccessMessage(successMessage: string): void {
        this.successMessage = successMessage;
        setTimeout(() => {
            this.setSuccessMessage('');
        }, 8000);
    }

    setWarningMessage(warningMessage: string): void {
        this.warningMessage = warningMessage;
    }

    setLoading(isActive: boolean): void {
        this.loading = isActive;
    }

    clearAllMessages(): void {
        this.errorMessage = '';
        this.successMessage = '';
        this.warningMessage = '';
        this.loading = false;
    }

    get translationKey(): string | null {
        if (isEnumValue(StatusMessageEnum, this.errorMessage)) {
            return Object.keys(StatusMessageEnum)[Object.values(StatusMessageEnum).indexOf(this.errorMessage)];
        }
        return null;
    }
}
