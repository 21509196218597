import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../../root-store';
import { AdminStatisticsMobxDto } from '../../../mobx/dtos/admin/statistic/admin-statistics-mobx-dto';

export class AdminStatisticsStore {
    rootStore: RootStore;

    statistics: AdminStatisticsMobxDto[] = [];

    loading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchStatistics(): Promise<void> {
        try {
            this.startLoading();
            const fetchedStatistics =
                await this.rootStore.apiClient.adminStatisticsController.adminStatisticsControllerGetAll();
            if (fetchedStatistics) {
                runInAction(() => {
                    this.statistics = AdminStatisticsMobxDto.createFromArray(fetchedStatistics);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }
}
