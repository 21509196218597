import { makeAutoObservable, runInAction } from 'mobx';
import { TaxReportEnum } from '@app/common';
import type { RootStore } from '../root-store';
import { TaxReportMobxDto } from '../../mobx/dtos/statistic/tax-report/tax-report-mobx-dto';
import { TaxReportControllerGetTaxReportRequest } from '../../../defs/api';

// eslint-disable-next-line @typescript-eslint/ban-types
export type TaxReportStoreHydration = {};

export class TaxReportStore {
    rootStore: RootStore;

    taxReports: TaxReportMobxDto[] = [];

    taxReportType: TaxReportEnum = TaxReportEnum.All;

    loading = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    getByYear(year: string): TaxReportMobxDto | undefined {
        return this.taxReports.find((tr) => tr.year === year);
    }

    async fetchTaxReports(): Promise<void> {
        const query: TaxReportControllerGetTaxReportRequest = { taxReportType: this.taxReportType };

        this.startLoading();

        try {
            const fetchedTaxReport = await this.rootStore.loadWithDelay(() => {
                return this.rootStore.apiClient.taxReportController.taxReportControllerGetTaxReport(query);
            });
            runInAction(() => {
                this.taxReports = TaxReportMobxDto.createFromArray(fetchedTaxReport);
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    setTaxReportType(type: TaxReportEnum): void {
        runInAction(() => {
            this.taxReportType = type;
        });
    }

    async updateTaxReports(type: TaxReportEnum): Promise<void> {
        runInAction(() => {
            this.taxReportType = type;
        });
        await this.fetchTaxReports();
    }
}
