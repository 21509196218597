import { makeAutoObservable } from 'mobx';
import { IconNameEnum, ColorEnum } from '@app/common';
import type { User } from './user';
import { Trade } from './trade';
import { Currency } from './currency';

export class Portfolio {
    public id: string = '';

    public name = '';

    public iconName: IconNameEnum | null = null;

    public colorName: ColorEnum | null = null;

    public currency: Currency | null = null;

    public userId: string | null = null;

    public user: User | null = null;

    public selected = false;

    public trades: Trade[] = [];

    public portfolioValue = 0;

    public invested = 0;

    public realizedPortfolioValue = 0;

    public realizedInvested = 0;

    public realizedProfit = 0;

    public realizedProfitPercentage = 0;

    public unrealizedProfit = 0;

    public unrealizedProfitPercentage = 0;

    public numberOfTrades = 0;

    public numberOfAssets = 0;

    public isRecalculating = false;

    public portfolioHistoryCurrencyCode = '';

    public processed = false;

    constructor() {
        makeAutoObservable(this);
    }

    setSelected(selected: boolean) {
        this.selected = selected;
    }

    setIsRecalculating(isRecalculating: boolean) {
        this.isRecalculating = isRecalculating;
    }
}
