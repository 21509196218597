import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
// eslint-disable-next-line import/no-cycle
import { SymbolAlternativesDialogStore } from './symbol-alternatives-dialog-store';
import { SymbolAlternativeMobxDto } from '../mobx/dtos/symbol-alternative/symbol-alternative-mobx-dto';
import { SymbolAlternativeControllerGetListRequest } from '../../defs/api';

export type SymbolAlternativesStoreHydration = {
    symbolAlternatives: SymbolAlternativeMobxDto[];
    totalSymbolAlternativesCount: number;
};

export class SymbolAlternativesStore {
    rootStore: RootStore;

    symbolAlternativesDialogStore: SymbolAlternativesDialogStore;

    loading = false;

    symbolAlternatives: SymbolAlternativeMobxDto[] = [];

    symbolAlternativesPage = 1;

    totalSymbolAlternativesCount = 0;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    filterPhrase = '';

    symbolDeleteInProgress = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.symbolAlternativesDialogStore = new SymbolAlternativesDialogStore(this);
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async fetchSymbolAlternatives(): Promise<void> {
        try {
            this.setIsLoading(true);
            const query: SymbolAlternativeControllerGetListRequest = {
                limit: this.limit,
                offset: (this.symbolAlternativesPage - 1) * this.limit,
                phrase: this.filterPhrase,
            };

            const fetchedSymbolAlternatives =
                await this.rootStore.apiClient.symbolAlternativesController.symbolAlternativeControllerGetList(query);
            if (fetchedSymbolAlternatives) {
                runInAction(() => {
                    this.symbolAlternatives = SymbolAlternativeMobxDto.createFromArray(
                        fetchedSymbolAlternatives?.items,
                    );
                    this.totalSymbolAlternativesCount = fetchedSymbolAlternatives?.total || 0;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.setIsLoading(false);
        }
    }

    setIsLoading(loading: boolean): void {
        this.loading = loading;
    }

    setSymbolDeleteInProgress(userDeleteInProgress: boolean): void {
        this.symbolDeleteInProgress = userDeleteInProgress;
    }

    async setActivePage(page: number): Promise<void> {
        this.symbolAlternativesPage = page;
        await this.fetchSymbolAlternatives();
    }

    get activePagesCount(): number {
        return Math.ceil(this.totalSymbolAlternativesCount / this.limit);
    }

    get hasSymbolAlternatives(): boolean {
        return this.symbolAlternatives.length > 0;
    }

    setPhraseFilter(phrase: string) {
        this.filterPhrase = phrase;
        this.fetchSymbolAlternatives();
    }

    async deleteSymbol(symbolId: string): Promise<void> {
        this.setSymbolDeleteInProgress(true);
        try {
            await this.rootStore.apiClient.symbolAlternativesController.symbolAlternativeControllerDelete({ symbolId });
            await this.fetchSymbolAlternatives();
        } catch (e) {
            this.rootStore.alertStore.setErrorMessage(e.message);
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.setSymbolDeleteInProgress(false);
        }
    }
}
