// eslint-disable-next-line
import { ProfitMonthDtoType } from '../../../schema/types';
import { SymbolDetailDto } from './symbol-detail-dto';

export class ProfitMonthDto implements ProfitMonthDtoType {
    month: number;

    profitPercentage: number;

    profitPerformance: number;

    profitAbsolute: number;

    bestEarner: SymbolDetailDto;

    worstEarner: SymbolDetailDto;
}
