// eslint-disable-next-line
import { ProfitYearDtoType } from '../../../schema/types';
import { ProfitMonthDto } from './profit-month-dto';

export class ProfitYearDto implements ProfitYearDtoType {
    year: number;

    months: ProfitMonthDto[];

    total: ProfitMonthDto;
}
