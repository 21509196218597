/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SymbolWithExchangeDto,
    SymbolWithExchangeDtoFromJSON,
    SymbolWithExchangeDtoFromJSONTyped,
    SymbolWithExchangeDtoToJSON,
} from './SymbolWithExchangeDto';

/**
 * 
 * @export
 * @interface SymbolFundamentalDataTechnicalsDto
 */
export interface SymbolFundamentalDataTechnicalsDto {
    /**
     * 
     * @type {SymbolWithExchangeDto}
     * @memberof SymbolFundamentalDataTechnicalsDto
     */
    symbol: SymbolWithExchangeDto;
    /**
     * 
     * @type {string}
     * @memberof SymbolFundamentalDataTechnicalsDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SymbolFundamentalDataTechnicalsDto
     */
    beta: number | null;
}

export function SymbolFundamentalDataTechnicalsDtoFromJSON(json: any): SymbolFundamentalDataTechnicalsDto {
    return SymbolFundamentalDataTechnicalsDtoFromJSONTyped(json, false);
}

export function SymbolFundamentalDataTechnicalsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SymbolFundamentalDataTechnicalsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoFromJSON(json['symbol']),
        'id': json['id'],
        'beta': json['beta'],
    };
}

export function SymbolFundamentalDataTechnicalsDtoToJSON(value?: SymbolFundamentalDataTechnicalsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'symbol': SymbolWithExchangeDtoToJSON(value.symbol),
        'id': value.id,
        'beta': value.beta,
    };
}

