import '../models/serializr-scheme';
import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import TagManager from 'react-gtm-module';
import { StatusMessageEnum } from '@app/common';
import { CookieService } from '../models/services/cookie-service';
import { GlobalStyle } from './_app.styled';
import '../../public/fonts/fonts.css';
import '../translation/i18n';
import { ThemeProvider } from '../providers/theme-provider';
import { RootStoreProvider } from '../providers/root-store-provider';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '../../public/cookie/cookie.css';
import useCookies from '../hooks/use-cookies';

const tagManagerArgs = {
    gtmId: 'GTM-TG5Q4R6',
};

type MyAppProps = AppProps & {
    host: string;
};

function MyApp({ Component, router, pageProps }: MyAppProps): React.ReactElement {
    const host = process.env.NEXT_PUBLIC_API_URL;
    if (!host) {
        throw new Error(StatusMessageEnum.EnvApiUrlUndefined);
    }

    const cookieService = new CookieService();

    const isApp = router.pathname.includes('/app');

    const { optionalCookieAccepted } = useCookies(isApp);

    useEffect(() => {
        if (optionalCookieAccepted) {
            TagManager.initialize(tagManagerArgs);
        }
    }, [router.route, optionalCookieAccepted]);

    return (
        <>
            <Head>
                <meta name="description" content="description" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
                <link rel="manifest" href="/images/favicon/site.webmanifest" />
                <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#231d61" />
                <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
                <meta name="theme-color" content="#ffffff" media="(prefers-color-scheme: light)" />
                <meta name="theme-color" content="#1A1A29" media="(prefers-color-scheme: dark)" />
                {/* <meta property="fb:page_id" content="275957925840243" /> */}
            </Head>

            <RootStoreProvider
                host={host}
                cookieService={cookieService}
                initialData={pageProps.initialData}
                isSSR={!isApp}
            >
                <ThemeProvider>
                    <GlobalStyle />
                    <Component {...pageProps} router={router} />
                </ThemeProvider>
            </RootStoreProvider>
        </>
    );
}

export default MyApp;
