import { makeAutoObservable } from 'mobx';
import { LayoutEnum, LookupKeyEnum, SubscriptionEnum, ThemeEnum, UserRoleEnum } from '@app/common';
import { Moment } from 'moment';
import { Portfolio } from './portfolio';

export class User {
    public id: string | null = null;

    public email: string | null = null;

    public isOnWaitingList: boolean | null = null;

    public fullName: string | null = null;

    public nickname: string | null = null;

    public role: string | null = null;

    public portfolios: Portfolio[] = [];

    public theme: ThemeEnum = ThemeEnum.Light;

    public layout: LayoutEnum = LayoutEnum.Horizontal;

    public newsletter = false;

    public subscription: SubscriptionEnum = SubscriptionEnum.Free;

    public lookupKey: LookupKeyEnum | null = null;

    public subscriptionEndDate: Moment | null = null;

    public hadTrialing: boolean = false;

    public interestedInSubscription: LookupKeyEnum | null = null;

    public freeSubscriptionAvailable: boolean = true;

    constructor() {
        makeAutoObservable(this, {});
    }

    get isAdmin(): boolean {
        return this.role === UserRoleEnum.Admin;
    }
}
