import { makeAutoObservable } from 'mobx';
import type { RootStore } from './root-store';
import { ProfileFormValuesMobxDto } from '../mobx/dtos/user/profile-form-values-mobx-dto';
import { LookupKeyEnum, MailOverviewPeriodEnum } from '../../defs/api';

export type UserStoreHydration = {
    newsletter: boolean;
};

export class UserStore {
    rootStore: RootStore;

    newsletter = false;

    loading = false;

    mailOverviewPeriod: MailOverviewPeriodEnum = MailOverviewPeriodEnum.Weekly;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.init();
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    hydrate(): UserStoreHydration {
        return {
            newsletter: this.newsletter,
        };
    }

    rehydrate(data: UserStoreHydration): void {
        this.newsletter = data.newsletter;
    }

    init(): void {
        this.newsletter = this.rootStore.authStore.userProfile?.newsletter || false;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    setMailOverviewPeriod(mailOverviewPeriod: MailOverviewPeriodEnum): void {
        this.mailOverviewPeriod = mailOverviewPeriod;
    }

    async updateUserProfile(values: ProfileFormValuesMobxDto): Promise<void> {
        this.startLoading();
        try {
            const { nicknameAlreadyTaken } =
                await this.rootStore.apiClient.userController.userControllerIsNicknameAlreadyTaken({
                    nickname: values.nickname,
                });

            if (nicknameAlreadyTaken) {
                this.rootStore.alertStore.setErrorMessage('Nickname is already taken');
                throw new Error('Nickname is already taken');
            }

            await this.rootStore.apiClient.userController.userControllerUpdateProfile({ profileFormValuesDto: values });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            this.stopLoading();
        }
    }

    async hadStornoDiscount(): Promise<boolean | null> {
        this.startLoading();
        try {
            return this.rootStore.apiClient.userController.userControllerHadStornoDiscount();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);

            return null;
        } finally {
            this.stopLoading();
        }
    }

    async fetchMailOverviewPeriod(): Promise<void> {
        this.startLoading();
        try {
            const period = await this.rootStore.apiClient.userController.userControllerGetMailOverviewPeriod();

            if (period?.mailOverviewPeriod) {
                this.setMailOverviewPeriod(period.mailOverviewPeriod);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async saveMailOverviewPeriod(): Promise<void> {
        this.startLoading();
        try {
            await this.rootStore.apiClient.userController.userControllerUpdateMailOverviewPeriod({
                mailOverviewPeriodDto: { mailOverviewPeriod: this.mailOverviewPeriod },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async updateInterestedInSubscription(lookupKey: LookupKeyEnum | null): Promise<void> {
        this.startLoading();
        try {
            await this.rootStore.apiClient.userController.userControllerUpdateInterestedInSubscription({
                interestedInSubscriptionDto: {
                    interestedInSubscription: lookupKey || undefined,
                },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        } finally {
            this.stopLoading();
        }
    }

    async persistInterestedInSubscription(): Promise<void> {
        const subscription = this.rootStore.cookieService.getInterestedInSubscription();
        if (subscription) {
            await this.updateInterestedInSubscription(subscription);
            this.rootStore.cookieService.setInterestedInSubscription(null);
        }
    }
}
