import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../root-store';
import { SymbolDetailChartStore } from './symbol-detail-chart-store';
import { TradeMobxDto } from '../../mobx/dtos/trade/trade-mobx-dto';
import { SymbolDividendMobxDto } from '../../mobx/dtos/symbol/symbol-dividend/symbol-dividend-mobx-dto';
import { SymbolSplitMobxDto } from '../../mobx/dtos/symbol/symbol-split-mobx-dto';
import { OrderEnum, SymbolTypeEnum } from '../../../defs/api';
import { SymbolDetailGeneralMobxDto } from '../../mobx/dtos/symbol/symbol-detail/symbol-detail-general-mobx-dto';
import { SymbolDetailHighlightMobxDto } from '../../mobx/dtos/symbol/symbol-detail/symbol-detail-highlight-mobx-dto';
import { SymbolDetailTechnicalsMobxDto } from '../../mobx/dtos/symbol/symbol-detail/symbol-detail-technicals-mobx-dto';
import { SymbolDetailInvestmentMobxDto } from '../../mobx/dtos/symbol/symbol-detail/symbol-detail-investment-mobx-dto';
import { WatchlistMobxDto } from '../../mobx/dtos/watchlist/watchlist-mobx-dto';
import { SymbolDetailCashFlowMobxDto } from '../../mobx/dtos/symbol/symbol-detail/symbol-detail-cash-flow-mobx-dto';

export class SymbolDetailStore {
    rootStore: RootStore;

    symbolDetailChartStore: SymbolDetailChartStore;

    general: SymbolDetailGeneralMobxDto | null = null;

    highlights: SymbolDetailHighlightMobxDto | null = null;

    technicals: SymbolDetailTechnicalsMobxDto | null = null;

    investments: SymbolDetailInvestmentMobxDto | null = null;

    symbolWatchlists: WatchlistMobxDto[] | null = [];

    cashFlow: SymbolDetailCashFlowMobxDto[] | null = null;

    loading = true;

    dividends: SymbolDividendMobxDto[] = [];

    trades: TradeMobxDto[] = [];

    splits: SymbolSplitMobxDto[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.symbolDetailChartStore = new SymbolDetailChartStore(this);

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async fetchDividends(symbolId: string): Promise<void> {
        try {
            const symbolDividendDtos =
                await this.rootStore.apiClient.symbolDividendsController.symbolDividendControllerGetBySymbolId({
                    symbolId,
                });

            if (symbolDividendDtos) {
                runInAction(() => {
                    this.dividends = SymbolDividendMobxDto.createFromArray(symbolDividendDtos);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    async fetchSplits(symbolId: string): Promise<void> {
        try {
            const symbolSplitDtos =
                await this.rootStore.apiClient.symbolSplitsController.symbolSplitControllerGetBySymbolId({
                    symbolId,
                });

            if (symbolSplitDtos) {
                runInAction(() => {
                    this.splits = SymbolSplitMobxDto.createFromArray(symbolSplitDtos);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchFundamentalDataAndInvestment(exchangeId: string, symbol: string): Promise<void> {
        try {
            this.general = null;
            this.highlights = null;
            this.technicals = null;
            this.investments = null;
            this.cashFlow = null;

            this.startLoading();

            const [fetchedDetail, fetchedInvestments] = await Promise.all([
                this.rootStore.apiClient.symbolController.symbolControllerGetFundamentalId({ exchangeId, symbol }),
                this.rootStore.apiClient.investmentController.investmentControllerGetInvestmentBySymbolId({
                    exchangeId,
                    symbolCode: symbol,
                }),
            ]);

            await Promise.all([
                this.fetchDividends(fetchedDetail?.general?.symbol?.id as string),
                this.fetchSplits(fetchedDetail?.general?.symbol?.id as string),
                this.fetchTrades(fetchedDetail?.general?.symbol?.id as string),
            ]);

            runInAction(() => {
                if (fetchedDetail) {
                    if (fetchedDetail.general) {
                        this.general = SymbolDetailGeneralMobxDto.create(fetchedDetail.general);
                    }
                    if (fetchedDetail.highlights) {
                        this.highlights = SymbolDetailHighlightMobxDto.create(fetchedDetail.highlights);
                    }
                    if (fetchedDetail.technicals) {
                        this.technicals = SymbolDetailTechnicalsMobxDto.create(fetchedDetail.technicals);
                    }
                    if (fetchedDetail.cashFlow) {
                        this.cashFlow = SymbolDetailCashFlowMobxDto.createFromArray(fetchedDetail.cashFlow);
                    }
                }
                if (fetchedInvestments) {
                    this.investments = SymbolDetailInvestmentMobxDto.create(fetchedInvestments);
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    async fetchTrades(symbolId: string): Promise<void> {
        try {
            const tradeDtos = await this.rootStore.apiClient.tradeController.tradeControllerListTradesBySymbolId({
                symbolId,
                date: OrderEnum.Desc,
            });

            runInAction(() => {
                if (tradeDtos) {
                    this.trades = TradeMobxDto.createFromArray(tradeDtos);
                }
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }

    setSymbolWatchlists(watchlists: WatchlistMobxDto[]) {
        this.symbolWatchlists = [...watchlists];
    }

    get isCommonStock() {
        return this.general?.symbol?.type === SymbolTypeEnum.Cs;
    }

    get isCrypto() {
        return this.general?.symbol?.type === SymbolTypeEnum.Ct;
    }

    get isEtf() {
        return this.general?.symbol?.type === SymbolTypeEnum.Et;
    }

    get hasWatchlists() {
        return this.symbolWatchlists && this.symbolWatchlists?.length > 0;
    }

    isSelectedWatchlist(watchlist: WatchlistMobxDto): boolean {
        return !!this.symbolWatchlists?.find((v) => v.id === watchlist.id);
    }

    get hasInvestments() {
        return this.investments && this.investments?.value !== 0 && this.investments?.amount !== 0;
    }
}
