import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../../root-store';
import { ActiveUserMobxDto } from '../../../mobx/dtos/admin/user/active-user-mobx-dto';
import { SubscriptionEnum, UserControllerGetActiveRequest } from '../../../../defs/api';

export type ActiveUsersStoreHydration = {
    activeUsers: ActiveUserMobxDto[];
    totalActiveUsersCount: number;
};

export class ActiveUsersStore {
    rootStore: RootStore;

    loading = false;

    activeUsers: ActiveUserMobxDto[] = [];

    activeUsersPage = 1;

    totalActiveUsersCount = 0;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    selectedSubscription = SubscriptionEnum.All;

    filterPhrase = '';

    userDeleteInProgress = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async fetchActiveUsers(): Promise<void> {
        try {
            this.setIsLoading(true);
            const query: UserControllerGetActiveRequest = {
                limit: this.limit,
                offset: (this.activeUsersPage - 1) * this.limit,
                subscription: this.selectedSubscription === SubscriptionEnum.All ? '' : this.selectedSubscription,
                phrase: this.filterPhrase,
            };
            const fetchedActiveUsers = await this.rootStore.apiClient.userController.userControllerGetActive(query);
            if (fetchedActiveUsers) {
                runInAction(() => {
                    this.activeUsers = ActiveUserMobxDto.createFromArray(fetchedActiveUsers.items);
                    this.totalActiveUsersCount = fetchedActiveUsers.total;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.setIsLoading(false);
        }
    }

    setIsLoading(loading: boolean): void {
        this.loading = loading;
    }

    setUserDeleteInProgress(userDeleteInProgress: boolean): void {
        this.userDeleteInProgress = userDeleteInProgress;
    }

    async setActivePage(page: number): Promise<void> {
        this.activeUsersPage = page;
        await this.fetchActiveUsers();
    }

    get activePagesCount(): number {
        return Math.ceil(this.totalActiveUsersCount / this.limit);
    }

    get hasActiveUsers(): boolean {
        return this.activeUsers.length > 0;
    }

    setSubscriptionFilter(subscription: SubscriptionEnum) {
        this.selectedSubscription = subscription;
        this.fetchActiveUsers();
    }

    setPhraseFilter(phrase: string) {
        this.filterPhrase = phrase;
        this.fetchActiveUsers();
    }

    async deleteUser(userId: string): Promise<void> {
        this.setUserDeleteInProgress(true);
        try {
            await this.rootStore.api.fetch<void>(`users/${userId}`, {
                method: 'DELETE',
            });
            await this.fetchActiveUsers();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.setUserDeleteInProgress(false);
        }
    }
}
