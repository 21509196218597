/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CancelSubscriptionDto,
    CancelSubscriptionDtoFromJSON,
    CancelSubscriptionDtoToJSON,
    CreateCheckoutSessionDto,
    CreateCheckoutSessionDtoFromJSON,
    CreateCheckoutSessionDtoToJSON,
    RedirectUrlDto,
    RedirectUrlDtoFromJSON,
    RedirectUrlDtoToJSON,
    SubscriptionPriceLookupKeyDto,
    SubscriptionPriceLookupKeyDtoFromJSON,
    SubscriptionPriceLookupKeyDtoToJSON,
    SwitchSubscriptionPriceResponseDto,
    SwitchSubscriptionPriceResponseDtoFromJSON,
    SwitchSubscriptionPriceResponseDtoToJSON,
} from '../models';

export interface StripeControllerCreateCheckoutSessionRequest {
    createCheckoutSessionDto: CreateCheckoutSessionDto;
}

export interface StripeControllerSwitchSubscriptionPriceRequest {
    subscriptionPriceLookupKeyDto: SubscriptionPriceLookupKeyDto;
}

export interface StripeControllerWebhookRequest {
    stripeSignature: string;
}

/**
 * 
 */
export class StripeApi extends runtime.BaseAPI {

    /**
     * Cancel subscription
     */
    async stripeControllerCancelSubscriptionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CancelSubscriptionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/stripe/cancel-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelSubscriptionDtoFromJSON(jsonValue));
    }

    /**
     * Cancel subscription
     */
    async stripeControllerCancelSubscription(initOverrides?: RequestInit): Promise<CancelSubscriptionDto> {
        const response = await this.stripeControllerCancelSubscriptionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create checkout session
     */
    async stripeControllerCreateCheckoutSessionRaw(requestParameters: StripeControllerCreateCheckoutSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RedirectUrlDto>> {
        if (requestParameters.createCheckoutSessionDto === null || requestParameters.createCheckoutSessionDto === undefined) {
            throw new runtime.RequiredError('createCheckoutSessionDto','Required parameter requestParameters.createCheckoutSessionDto was null or undefined when calling stripeControllerCreateCheckoutSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/stripe/create-checkout-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCheckoutSessionDtoToJSON(requestParameters.createCheckoutSessionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectUrlDtoFromJSON(jsonValue));
    }

    /**
     * Create checkout session
     */
    async stripeControllerCreateCheckoutSession(requestParameters: StripeControllerCreateCheckoutSessionRequest, initOverrides?: RequestInit): Promise<RedirectUrlDto> {
        const response = await this.stripeControllerCreateCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create portal session
     */
    async stripeControllerCreatePortalSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RedirectUrlDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/stripe/create-portal-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectUrlDtoFromJSON(jsonValue));
    }

    /**
     * Create portal session
     */
    async stripeControllerCreatePortalSession(initOverrides?: RequestInit): Promise<RedirectUrlDto> {
        const response = await this.stripeControllerCreatePortalSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get subscriptin price
     */
    async stripeControllerGetSubscriptionPriceRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubscriptionPriceLookupKeyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/stripe/subscription-price`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionPriceLookupKeyDtoFromJSON(jsonValue));
    }

    /**
     * Get subscriptin price
     */
    async stripeControllerGetSubscriptionPrice(initOverrides?: RequestInit): Promise<SubscriptionPriceLookupKeyDto> {
        const response = await this.stripeControllerGetSubscriptionPriceRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reactivate subscription
     */
    async stripeControllerReactivateSubscriptionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/stripe/reactivate-subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reactivate subscription
     */
    async stripeControllerReactivateSubscription(initOverrides?: RequestInit): Promise<void> {
        await this.stripeControllerReactivateSubscriptionRaw(initOverrides);
    }

    /**
     * Changed subscription price
     */
    async stripeControllerSwitchSubscriptionPriceRaw(requestParameters: StripeControllerSwitchSubscriptionPriceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SwitchSubscriptionPriceResponseDto>> {
        if (requestParameters.subscriptionPriceLookupKeyDto === null || requestParameters.subscriptionPriceLookupKeyDto === undefined) {
            throw new runtime.RequiredError('subscriptionPriceLookupKeyDto','Required parameter requestParameters.subscriptionPriceLookupKeyDto was null or undefined when calling stripeControllerSwitchSubscriptionPrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/stripe/switch-subscription-price`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionPriceLookupKeyDtoToJSON(requestParameters.subscriptionPriceLookupKeyDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwitchSubscriptionPriceResponseDtoFromJSON(jsonValue));
    }

    /**
     * Changed subscription price
     */
    async stripeControllerSwitchSubscriptionPrice(requestParameters: StripeControllerSwitchSubscriptionPriceRequest, initOverrides?: RequestInit): Promise<SwitchSubscriptionPriceResponseDto> {
        const response = await this.stripeControllerSwitchSubscriptionPriceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Webhook
     */
    async stripeControllerWebhookRaw(requestParameters: StripeControllerWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stripeSignature === null || requestParameters.stripeSignature === undefined) {
            throw new runtime.RequiredError('stripeSignature','Required parameter requestParameters.stripeSignature was null or undefined when calling stripeControllerWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.stripeSignature !== undefined && requestParameters.stripeSignature !== null) {
            headerParameters['stripe-signature'] = String(requestParameters.stripeSignature);
        }

        const response = await this.request({
            path: `/api/stripe/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook
     */
    async stripeControllerWebhook(requestParameters: StripeControllerWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.stripeControllerWebhookRaw(requestParameters, initOverrides);
    }

}
