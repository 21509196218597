// eslint-disable-next-line
import { ProfitDtoType } from '../../../schema/types';
import { ProfitYearDto } from './profit-year-dto';

export class ProfitDto implements ProfitDtoType {
    years: ProfitYearDto[];

    numberOfMonths: number;

    totalProfit: number;

    averageProfit: number;
}
