/* tslint:disable */
/* eslint-disable */
/**
 * Portfolio-tracker
 * Portfolio-tracker
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SymbolGrowthRateWithHighlightsDto,
    SymbolGrowthRateWithHighlightsDtoFromJSON,
    SymbolGrowthRateWithHighlightsDtoToJSON,
    UpdateSymbolGrowthRateDto,
    UpdateSymbolGrowthRateDtoFromJSON,
    UpdateSymbolGrowthRateDtoToJSON,
} from '../models';

export interface SymbolGrowthRateControllerGetBySymbolIdRequest {
    symbolId: string;
}

export interface SymbolGrowthRateControllerUpdateRequest {
    updateSymbolGrowthRateDto: UpdateSymbolGrowthRateDto;
}

/**
 * 
 */
export class SymbolGrowthRatesApi extends runtime.BaseAPI {

    /**
     * Get symbol growth rates
     */
    async symbolGrowthRateControllerGetBySymbolIdRaw(requestParameters: SymbolGrowthRateControllerGetBySymbolIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SymbolGrowthRateWithHighlightsDto>> {
        if (requestParameters.symbolId === null || requestParameters.symbolId === undefined) {
            throw new runtime.RequiredError('symbolId','Required parameter requestParameters.symbolId was null or undefined when calling symbolGrowthRateControllerGetBySymbolId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-growth-rates/{symbolId}`.replace(`{${"symbolId"}}`, encodeURIComponent(String(requestParameters.symbolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SymbolGrowthRateWithHighlightsDtoFromJSON(jsonValue));
    }

    /**
     * Get symbol growth rates
     */
    async symbolGrowthRateControllerGetBySymbolId(requestParameters: SymbolGrowthRateControllerGetBySymbolIdRequest, initOverrides?: RequestInit): Promise<SymbolGrowthRateWithHighlightsDto> {
        const response = await this.symbolGrowthRateControllerGetBySymbolIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update symbol growth rate
     */
    async symbolGrowthRateControllerUpdateRaw(requestParameters: SymbolGrowthRateControllerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateSymbolGrowthRateDto === null || requestParameters.updateSymbolGrowthRateDto === undefined) {
            throw new runtime.RequiredError('updateSymbolGrowthRateDto','Required parameter requestParameters.updateSymbolGrowthRateDto was null or undefined when calling symbolGrowthRateControllerUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/symbol-growth-rates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSymbolGrowthRateDtoToJSON(requestParameters.updateSymbolGrowthRateDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update symbol growth rate
     */
    async symbolGrowthRateControllerUpdate(requestParameters: SymbolGrowthRateControllerUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.symbolGrowthRateControllerUpdateRaw(requestParameters, initOverrides);
    }

}
