// eslint-disable-next-line
import { SymbolDetailDtoType } from '../../../schema/types';

export class SymbolDetailDto implements SymbolDetailDtoType {
    symbol: string;

    valueChangePercentage: number;

    valueChangeAbsolute: number;
}
