import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';

export class MailStore {
    rootStore: RootStore;

    loading = false;

    currentTemplate: string | null = null;

    currentTemplateContent: string | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchTemplateContent(
        templateName: string,
        variables: { [key: string]: string | number | object | boolean },
    ): Promise<void> {
        this.startLoading();
        try {
            const templateContent = await this.rootStore.apiClient.mailController.mailControllerGetTemplateContent({
                templateName,
                variables: JSON.stringify(variables),
            });

            runInAction(() => {
                this.currentTemplate = templateName;
                this.currentTemplateContent = templateContent;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }
}
