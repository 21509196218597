import { makeAutoObservable, runInAction } from 'mobx';
import { indexSymbols } from '@app/common';
import type { RootStore } from '../root-store';
import type { StatisticStore } from './statistic-store';
import { formatApiDate } from '../../functions/format-api-date';
import { TabEnum } from '../../enums/tab-enum';
import { AreaChartMobxDto } from '../../mobx/dtos/statistic/area-chart-mobx-dto';
import { StatisticControllerGetProfitChartsRequest } from '../../../defs/api';

export class ProfitStore {
    rootStore: RootStore;

    statisticStore: StatisticStore;

    percentageProfits: AreaChartMobxDto[] = [];

    absoluteProfits: AreaChartMobxDto[] = [];

    filter: Map<string, boolean> = new Map<string, boolean>();

    tab = TabEnum.Absolute;

    showChart: boolean = false;

    loading = true;

    constructor(statisticStore: StatisticStore) {
        this.statisticStore = statisticStore;
        this.rootStore = statisticStore.rootStore;

        makeAutoObservable(this);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    setShowChart(fetched: boolean = true) {
        this.showChart = fetched;
    }

    async fetchProfitCharts(): Promise<void> {
        const portfolioId = this.rootStore.portfolioStore.selectedPortfolio.id;

        this.absoluteProfits = [];
        this.percentageProfits = [];

        const parameters: StatisticControllerGetProfitChartsRequest = { portfolioId };

        if (this.statisticStore.dateFrom) {
            parameters.dateFrom = formatApiDate(this.statisticStore.dateFrom);
        }

        this.startLoading();

        try {
            const fetchedAbsoluteProfit = await this.rootStore.loadWithDelay(() => {
                const isSharedPortfolio = this.rootStore.portfolioStore.sharedPortfolio !== null;
                return isSharedPortfolio
                    ? this.rootStore.apiClient.statisticsController.sharedStatisticControllerGetProfitCharts(parameters)
                    : this.rootStore.apiClient.statisticsController.statisticControllerGetProfitCharts(parameters);
            });
            if (fetchedAbsoluteProfit) {
                runInAction(() => {
                    this.absoluteProfits = AreaChartMobxDto.createFromArray(fetchedAbsoluteProfit.absoluteProfits);
                    this.percentageProfits = AreaChartMobxDto.createFromArray(fetchedAbsoluteProfit.percentageProfits);

                    this.filterSelected();
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    setTab(tab: TabEnum) {
        this.tab = tab;
    }

    filterSelected(id?: string): void {
        if (id) {
            const indexSymbolsSymbols = indexSymbols.map((is) => is.symbol);
            if (indexSymbolsSymbols.indexOf(id) !== -1 && this.rootStore.authStore.userHasFreeTariff) {
                this.rootStore.premiumDialogStore.open();
            } else {
                const filterValue = this.filter.get(id);
                runInAction(() => {
                    this.filter.set(id, !filterValue);
                });
            }
        } else {
            const symbols = indexSymbols.map((s) => s.symbol);

            this.absoluteProfits.forEach((chart) => {
                this.filter.set(chart.id, false);
            });
            this.percentageProfits.forEach((chart) => {
                this.filter.set(chart.id, false);
            });

            if (this.tab === TabEnum.Absolute) {
                this.absoluteProfits.forEach((chart) => {
                    const isIndexChart = symbols.includes(chart.id);
                    this.filter.set(chart.id, !isIndexChart);
                });
            } else if (this.tab === TabEnum.Percentage) {
                this.percentageProfits.forEach((chart) => {
                    const isIndexChart = symbols.includes(chart.id);
                    this.filter.set(chart.id, !isIndexChart);
                });
            }
        }

        runInAction(() => {
            this.setShowChart(true);
        });
    }
}
