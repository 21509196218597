import { makeAutoObservable, runInAction } from 'mobx';

import type { RootStore } from '../root-store';
import type { StatisticStore } from './statistic-store';
import { formatApiDate } from '../../functions/format-api-date';
// eslint-disable-next-line
// import { StatisticControllerGetOverview200 } from '../../../schema/types';
import { routes } from '../../../config/routes';
import { CookieService } from '../../services/cookie-service';
import { OverviewMobxDto } from '../../mobx/dtos/statistic/overview-mobx-dto';

export type OverviewStoreHydration = {
    overview: OverviewMobxDto;
};

export class OverviewStore {
    rootStore: RootStore;

    statisticStore: StatisticStore;

    overview: OverviewMobxDto | null = null;

    overviewDirtyFields: (keyof OverviewMobxDto)[] | null = [];

    loading = true;

    private delayTimer: NodeJS.Timeout;

    calculateFees = false;

    private readonly cookieService: CookieService;

    constructor(statisticStore: StatisticStore) {
        this.statisticStore = statisticStore;
        this.rootStore = statisticStore.rootStore;
        this.cookieService = new CookieService();
        this.calculateFees = this.cookieService.getCalculateFees();
        makeAutoObservable(this);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async setCalculateFees(calculateFees: boolean): Promise<void> {
        this.calculateFees = calculateFees;
        this.rootStore.cookieService.setCalculateFees(calculateFees);
        await this.fetchOverview();
    }

    async fetchOverview(): Promise<void> {
        const portfolioId = this.rootStore.portfolioStore.selectedPortfolio.id;

        const parameters: {
            portfolioId: string;
            calculateFees: boolean;
            dateFrom?: string;
        } = { portfolioId, calculateFees: this.calculateFees };

        if (this.statisticStore.dateFrom) {
            parameters.dateFrom = formatApiDate(this.statisticStore.dateFrom);
        }

        this.startLoading();

        try {
            const fetchedOverview = await this.rootStore.loadWithDelay(() => {
                const isSharedPortfolio = this.rootStore.portfolioStore.sharedPortfolio !== null;
                return isSharedPortfolio
                    ? this.rootStore.apiClient.statisticsController.sharedStatisticControllerGetOverview(parameters)
                    : this.rootStore.apiClient.statisticsController.statisticControllerGetOverview(parameters);
            });

            if (fetchedOverview) {
                runInAction(() => {
                    const newOverview = OverviewMobxDto.create(fetchedOverview);
                    Object.keys(newOverview).map((key: string) => {
                        if (this.overview) {
                            if (
                                newOverview[key as keyof OverviewMobxDto] !==
                                this.overview[key as keyof OverviewMobxDto]
                            ) {
                                this.setIsFieldDirty(key as keyof OverviewMobxDto);
                            }
                        }
                        return null;
                    });
                    this.overview = newOverview;
                    if (this.overview.isRecalculating) {
                        window.location.replace(routes.app.trades);
                    }
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    getIsFieldDirty(field: keyof OverviewMobxDto): boolean {
        if (this.overviewDirtyFields?.indexOf(field) !== -1) {
            return true;
        }
        return false;
    }

    setIsFieldDirty(field: keyof OverviewMobxDto) {
        runInAction(() => {
            if (this.overviewDirtyFields) {
                if (this.overviewDirtyFields.indexOf(field) === -1) {
                    this.overviewDirtyFields.push(field);
                } else {
                    this.overviewDirtyFields = this.overviewDirtyFields?.filter((f) => field !== f) || [];
                }
            }
        });
    }

    removeDirtyFieldsWithDelay() {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
            runInAction(() => {
                if (this.overviewDirtyFields) {
                    this.overviewDirtyFields = [];
                }
            });
        }, 900);
    }
}
