import { makeAutoObservable } from 'mobx';
import { Api } from '../api';
import { PortfolioStore, PortfolioStoreHydration } from './portfolio-store';
import { TradeStore, TradeStoreHydration } from './trade-store';
import { CurrencyStore, CurrencyStoreHydration } from './currency-store';
import { AuthStore, AuthStoreHydration } from './auth-store';
import { ThemeStore, ThemeStoreHydration } from './theme-store';
import { StatisticStore, StatisticStoreHydration } from './statistic/statistic-store';
import { AlertStore, AlertStoreHydration } from './alert-store';
import { DividendsStore, DividendsStoreHydration } from './dividends-store';
import { CookieService } from '../services/cookie-service';
import { StripeStore } from './stripe-store';
import { InvestmentsStore, InvestmentsStoreHydration } from './statistic/investments-store';
import { UserStore, UserStoreHydration } from './user-store';
import { ProfitsStore, ProfitsStoreHydration } from './profits-store';
import { TaxReportStore, TaxReportStoreHydration } from './statistic/tax-report-store';
import { PremiumDialogStore } from './premium-dialog-store';
import { ActiveUsersStore, ActiveUsersStoreHydration } from './admin/user/active-users-store';
import { WaitingUsersStore, WaitingUsersStoreHydration } from './admin/user/waiting-users-store';
import { SymbolsStore, SymbolsStoreHydration } from './symbols-store';
import { PortfolioListStore } from './admin/portfolio/portfolio-list-store';
import { WatchlistStore, WatchlistStoreHydration } from './watchlist-store';
import { AdminStatisticsStore } from './admin/statistic/admin-statistics-store';
import { PrivateStore } from './private-store';
import { OffboardingStore } from './offboarding-store';
import { BannerYearlyPremiumStore } from './banner-yearly-premium-store';
import { LeaderboardStore } from './leaderboard-store';
import { HomepageStore, HomepageStoreHydration } from './homepage-store';
import { NotConfirmedUsersStore, NotConfirmedUsersStoreHydration } from './admin/user/not-confirmed-users-store';
import { SymbolAlternativesStore, SymbolAlternativesStoreHydration } from './symbol-alternatives-store';
import { PortfolioIntegrationStore } from './portfolio-integration-store';
import ApiClient from '../api-client';
import { PortfolioValueCalculationInfoDialogStore } from './portfolio-value-calculation-info-dialog-store';
import { MailStore } from './mail-store';

const SKELETON_LOAD_DELAY_MS = 250;

export type RootStoreHydration = {
    portfolioStore?: PortfolioStoreHydration;
    tradeStore?: TradeStoreHydration;
    currencyStore?: CurrencyStoreHydration;
    authStore?: AuthStoreHydration;
    statisticStore?: StatisticStoreHydration;
    alertStore?: AlertStoreHydration;
    themeStore?: ThemeStoreHydration;
    dividendsStore?: DividendsStoreHydration;
    investmentsStore?: InvestmentsStoreHydration;
    userStore?: UserStoreHydration;
    activeUsersStore?: ActiveUsersStoreHydration;
    waitingUsersStore?: WaitingUsersStoreHydration;
    notConfirmedUsersStore?: NotConfirmedUsersStoreHydration;
    profitsStore?: ProfitsStoreHydration;
    symbolsStore?: SymbolsStoreHydration;
    taxReportStore?: TaxReportStoreHydration;
    watchlistStore?: WatchlistStoreHydration;
    premiumDialogStore?: PremiumDialogStore;
    portfolioValueCalculationInfoDialogStore?: PortfolioValueCalculationInfoDialogStore;
    offboardingStore?: OffboardingStore;
    bannerYearlyPremiumStore?: BannerYearlyPremiumStore;
    leaderboardStore?: LeaderboardStore;
    homepageStore?: HomepageStoreHydration;
    alternativeSymbols?: SymbolAlternativesStoreHydration;
    mailStore?: MailStore;
};

export class RootStore {
    authStore: AuthStore;

    portfolioStore: PortfolioStore;

    portfolioIntegrationStore: PortfolioIntegrationStore;

    portfolioListStore: PortfolioListStore;

    tradeStore: TradeStore;

    currencyStore: CurrencyStore;

    themeStore: ThemeStore;

    statisticStore: StatisticStore;

    alertStore: AlertStore;

    dividendsStore: DividendsStore;

    stripeStore: StripeStore;

    investmentsStore: InvestmentsStore;

    taxReportStore: TaxReportStore;

    activeUsersStore: ActiveUsersStore;

    waitingUsersStore: WaitingUsersStore;

    notConfirmedUsersStore: NotConfirmedUsersStore;

    symbolsStore: SymbolsStore;

    userStore: UserStore;

    watchlistStore: WatchlistStore;

    profitsStore: ProfitsStore;

    premiumDialogStore: PremiumDialogStore;

    portfolioValueCalculationInfoDialogStore: PortfolioValueCalculationInfoDialogStore;

    adminStatisticsStore: AdminStatisticsStore;

    offboardingStore: OffboardingStore;

    privateStore: PrivateStore;

    bannerYearlyPremiumStore: BannerYearlyPremiumStore;

    leaderboardStore: LeaderboardStore;

    homepageStore: HomepageStore;

    symbolAlternativesStore: SymbolAlternativesStore;

    mailStore: MailStore;

    host: string;

    api: Api;

    apiClient: ApiClient;

    cookieService: CookieService;

    constructor(host: string, api: Api, apiClient: ApiClient) {
        this.authStore = new AuthStore(this);
        this.portfolioStore = new PortfolioStore(this);
        this.portfolioIntegrationStore = new PortfolioIntegrationStore(this);
        this.tradeStore = new TradeStore(this);
        this.themeStore = new ThemeStore(this);
        this.currencyStore = new CurrencyStore(this);
        this.statisticStore = new StatisticStore(this);
        this.alertStore = new AlertStore(this);
        this.dividendsStore = new DividendsStore(this);
        this.stripeStore = new StripeStore(this);
        this.investmentsStore = new InvestmentsStore(this);
        this.taxReportStore = new TaxReportStore(this);
        this.waitingUsersStore = new WaitingUsersStore(this);
        this.activeUsersStore = new ActiveUsersStore(this);
        this.notConfirmedUsersStore = new NotConfirmedUsersStore(this);
        this.userStore = new UserStore(this);
        this.symbolsStore = new SymbolsStore(this);
        this.profitsStore = new ProfitsStore(this);
        this.watchlistStore = new WatchlistStore(this);
        this.premiumDialogStore = new PremiumDialogStore(this);
        this.portfolioValueCalculationInfoDialogStore = new PortfolioValueCalculationInfoDialogStore(this);
        this.portfolioListStore = new PortfolioListStore(this);
        this.adminStatisticsStore = new AdminStatisticsStore(this);
        this.offboardingStore = new OffboardingStore(this);
        this.bannerYearlyPremiumStore = new BannerYearlyPremiumStore(this);
        this.leaderboardStore = new LeaderboardStore(this);
        this.homepageStore = new HomepageStore(this);
        this.symbolAlternativesStore = new SymbolAlternativesStore(this);
        this.mailStore = new MailStore(this);
        this.privateStore = new PrivateStore(this, api.cookieService);
        this.host = host;
        this.api = api;
        this.apiClient = apiClient;

        makeAutoObservable(this, {
            host: false,
            api: false,
            cookieService: false,
        });
    }

    rehydrate(data: RootStoreHydration): void {
        if (data.portfolioStore) {
            this.portfolioStore.rehydrate(data.portfolioStore);
        }

        if (data.currencyStore) {
            this.currencyStore.rehydrate(data.currencyStore);
        }

        if (data.authStore) {
            this.authStore.rehydrate(data.authStore);
        }

        if (data.alertStore) {
            this.alertStore.rehydrate(data.alertStore);
        }

        if (data.themeStore) {
            this.themeStore.rehydrate(data.themeStore);
        }

        if (data.userStore) {
            this.userStore.rehydrate(data.userStore);
        }

        if (data.homepageStore) {
            this.homepageStore.rehydrate(data.homepageStore);
        }
    }

    loadWithDelay<T>(operation: () => Promise<T>): Promise<T> {
        try {
            const start = performance.now();
            const result = operation();
            const end = performance.now();

            const duration = end - start;
            const waitTime = SKELETON_LOAD_DELAY_MS - duration;

            // eslint-disable-next-line no-promise-executor-return
            const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

            return wait(waitTime >= 0 ? waitTime : 0).then(() => {
                return result;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            throw e;
        }
    }
}
