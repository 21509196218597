import { makeAutoObservable } from 'mobx';
// eslint-disable-next-line
import { YearDtoType } from '../../../schema/types';
import { MonthDto } from './month-dto';

export class YearDto implements YearDtoType {
    year: number;

    months: MonthDto[];

    constructor() {
        makeAutoObservable(this);
    }

    get totalWithSell(): number {
        return this.months.reduce((p1, p2) => p1 + p2.amountWithSell, 0);
    }

    get totalWithoutSell(): number {
        return this.months.reduce((p1, p2) => p1 + p2.amountWithoutSell, 0);
    }
}
