import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from './root-store';
import { CurrencyMobxDto } from '../mobx/dtos/currency/currency-mobx-dto';

export type CurrencyStoreHydration = {
    currencies: CurrencyMobxDto[];
    currency: CurrencyMobxDto | null;
};

export class CurrencyStore {
    rootStore: RootStore;

    currencies: CurrencyMobxDto[] = [];

    currency: CurrencyMobxDto | null = null;

    loading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    hydrate(): CurrencyStoreHydration {
        return { currencies: this.currencies, currency: this.currency };
    }

    rehydrate(data: CurrencyStoreHydration): void {
        if (data.currencies) {
            this.currencies = CurrencyMobxDto.createFromArray(data.currencies);
        }
        if (data.currency) {
            this.currency = CurrencyMobxDto.create(data.currency);
        }
    }

    async fetchCurrencyList(): Promise<void> {
        try {
            this.startLoading();
            const fetchedCurrencies = await this.rootStore.apiClient.currencyController.currencyControllerGetList();
            if (fetchedCurrencies) {
                runInAction(() => {
                    this.currencies = CurrencyMobxDto.createFromArray(fetchedCurrencies);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    hasCurrency(currencyCode: string | undefined) {
        const currency = this.currencies.find((c) => c.code === currencyCode);
        return !!currency;
    }
}
