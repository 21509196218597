import { makeAutoObservable } from 'mobx';
import { StornoReasonEnum } from '@app/common';
// eslint-disable-next-line
import { UserControllerUpdateStornoReasonBody } from '../../schema/types';
import type { RootStore } from './root-store';

export class OffboardingStore {
    rootStore: RootStore;

    opened: boolean = false;

    loading = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    open(): void {
        this.opened = true;
    }

    close(): void {
        this.opened = false;
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async updateStornoReason(stornoReason: StornoReasonEnum | null, stornoReasonDescription: string): Promise<void> {
        try {
            this.startLoading();

            await this.rootStore.api.fetch<void, UserControllerUpdateStornoReasonBody>('users/storno-reason', {
                method: 'PUT',
                data: { stornoReason, stornoReasonDescription },
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }
}
