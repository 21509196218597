import { makeAutoObservable } from 'mobx';
// eslint-disable-next-line
import { InvestmentsDtoType } from '../../../schema/types';
import { YearDto } from './year-dto';

export class InvestmentsDto implements InvestmentsDtoType {
    years: YearDto[];

    numberOfMonths: number;

    totalAmountWithoutSell: number;

    totalAmountWithSell: number;

    averageAmountWithoutSell: number;

    averageAmountWithSell: number;

    constructor() {
        makeAutoObservable(this);
    }
}
