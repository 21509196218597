import { createGlobalStyle, css } from 'styled-components';
import { getOpacity } from '../theme/utils/get-rgb';

export const GlobalStyle = createGlobalStyle`

    *,
    *:before,
    *:after {
        box-sizing: border-box;
        backface-visibility: hidden;
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
    }

    html {
        font-size: 62.5%;
        height: 100%;
        scroll-behavior: smooth;
    }

    body {
        ${({ theme }) => css`
            scroll-behavior: smooth;
            font-family: ${theme.base.fontFace};
            font-weight: ${theme.base.fontWeight};
            background-color: ${theme.palette.background.body};
            color: ${theme.palette.common.textMuted};
            font-size: ${theme.text.size.medium.fontSize};
            line-height: ${theme.text.size.medium.lineHeight};
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            .MuiPaper-root {
                margin: 1rem auto;
                max-width: 100%;

                border-radius: ${theme.radius.medium};
                border: 0.1rem solid ${theme.card.variant.default.border};
                padding: ${theme.gap.xsmall};
                box-shadow: ${theme.shadow.medium};
                background: ${theme.card.variant.default.background};
                color: ${theme.palette.common.text};

                * {
                    font-family: ${theme.base.fontFace};
                    font-size: ${theme.text.size.medium.fontSize};
                    font-weight: ${theme.base.fontWeight};
                    line-height: ${theme.text.size.medium.lineHeight};
                }

                .MuiIconButton-root {
                    color: ${theme.palette.common.textMuted};
                    transition: color ${theme.base.transition};

                    &:hover {
                        color: ${theme.palette.color.primary.main};
                    }
                }

                .MuiMenuItem-root + .MuiMenuItem-root {
                    margin-top: 0.2rem;
                }

                .MuiList-root {
                    margin: 0;
                    padding: 0;
                }

                .MuiButtonBase-root {
                    border-radius: ${theme.radius.medium};
                    padding: 1rem;
                }

                .MuiSvgIcon-root {
                    font-size: 2.6rem;
                }

                .MuiDayCalendar-weekDayLabel {
                    color: ${theme.palette.common.textMuted};
                    font-weight: 700;
                }

                .MuiDayCalendar-weekContainer {
                    margin: 1rem 0;
                }

                .MuiButton-textPrimary {
                    background-color: ${theme.palette.color.primary.main} !important;
                    color: ${theme.palette.color.secondary.main} !important;
                    font-weight: 700;
                }

                .MuiPickersDay-root {
                    color: ${theme.palette.common.textMuted};
                    transition: background ${theme.base.transition};

                    &:hover {
                        background-color: ${getOpacity(theme.palette.color.primary.main, 0.1)};
                    }
                }

                .MuiPickersDay-today {
                    background-color: unset !important;
                    border: 0.1rem solid ${theme.palette.color.primary.main} !important;
                }

                .Mui-selected {
                    background: ${theme.palette.gradient.primary.main} !important;
                    color: ${theme.palette.color.white.main} !important;
                    font-weight: 700;
                }

                .MuiPickersYear-yearButton {
                    transition: background ${theme.base.transition};

                    &:hover {
                        background-color: ${getOpacity(theme.palette.color.primary.main, 0.1)};
                    }
                }

                .MuiPickersDay-root.Mui-disabled {
                    color: ${theme.palette.common.textMuted};
                    opacity: 0.5;
                }
            }
        `}
    }
`;
