import { makeAutoObservable } from 'mobx';
import { ActiveUserDto, SubscriptionEnum } from '../../../../../defs/api';

export class ActiveUserMobxDto implements ActiveUserDto {
    public id: string = '';

    public fullName: string = '';

    public email: string = '';

    public portfoliosCount: number = 0;

    public tradesCount: number = 0;

    public assetsCount: number = 0;

    public subscription: SubscriptionEnum = SubscriptionEnum.Free;

    public lastLoginDate: string = '';

    public registrationDate: Date = new Date();

    public stripeCustomerId: string = '';

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: ActiveUserDto): ActiveUserMobxDto {
        const mobxDto = new ActiveUserMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: ActiveUserDto[]): ActiveUserMobxDto[] {
        return dtos.map((dto) => ActiveUserMobxDto.create(dto));
    }
}
