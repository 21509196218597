import { makeAutoObservable, runInAction } from 'mobx';
import type { RootStore } from '../../root-store';
import { NotConfirmedUserMobxDto } from '../../../mobx/dtos/admin/user/not-confirmed-user-mobx-dto';
import { UserControllerGetNotConfirmedRequest } from '../../../../defs/api';

export type NotConfirmedUsersStoreHydration = {
    notConfirmedUsers: NotConfirmedUserMobxDto[];
    notConfirmedUsersCount: number;
};

export class NotConfirmedUsersStore {
    rootStore: RootStore;

    loading = false;

    notConfirmedUsers: NotConfirmedUserMobxDto[] = [];

    notConfirmedUsersPage = 1;

    notConfirmedUsersCount = 0;

    limit = Number(process.env.NEXT_PUBLIC_PAGE_LIMIT);

    filterPhrase = '';

    userDeleteInProgress = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
        });
    }

    async fetchNotConfirmedUserList(): Promise<void> {
        try {
            this.setIsLoading(true);
            const query: UserControllerGetNotConfirmedRequest = {
                limit: this.limit,
                offset: (this.notConfirmedUsersPage - 1) * this.limit,
                phrase: this.filterPhrase,
            };

            const fetchedNotConfirmedUsers =
                await this.rootStore.apiClient.userController.userControllerGetNotConfirmed(query);

            if (fetchedNotConfirmedUsers) {
                runInAction(() => {
                    this.notConfirmedUsers = NotConfirmedUserMobxDto.createFromArray(fetchedNotConfirmedUsers.items);
                    this.notConfirmedUsersCount = fetchedNotConfirmedUsers.total;
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.setIsLoading(false);
        }
    }

    setIsLoading(loading: boolean): void {
        this.loading = loading;
    }

    setUserDeleteInProgress(userDeleteInProgress: boolean): void {
        this.userDeleteInProgress = userDeleteInProgress;
    }

    async setNotConfirmedPage(page: number): Promise<void> {
        this.notConfirmedUsersPage = page;
        await this.fetchNotConfirmedUserList();
    }

    get notConfirmedPagesCount(): number {
        return Math.ceil(this.notConfirmedUsersCount / this.limit);
    }

    get hasNotConfirmedUsers(): boolean {
        return this.notConfirmedUsers.length > 0;
    }

    setPhraseFilter(phrase: string) {
        this.filterPhrase = phrase;
        this.fetchNotConfirmedUserList();
    }

    async deleteUser(userId: string): Promise<void> {
        this.setUserDeleteInProgress(true);
        try {
            await this.rootStore.apiClient.userController.userControllerDeleteUser({ userId });
            await this.fetchNotConfirmedUserList();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.setUserDeleteInProgress(false);
        }
    }
}
