import { makeAutoObservable } from 'mobx';
import { LayoutEnum, SubscriptionEnum, ThemeEnum, UserRoleEnum } from '@app/common';
import { LookupKeyEnum, UserProfileDto } from '../../../../defs/api';

export class UserProfileMobxDto implements UserProfileDto {
    public id: string = '';

    public email: string = '';

    public isOnWaitingList: boolean = false;

    public fullName: string = '';

    public nickname: string = '';

    public role: UserRoleEnum = UserRoleEnum.User;

    public theme: ThemeEnum = ThemeEnum.Light;

    public layout: LayoutEnum = LayoutEnum.Horizontal;

    public newsletter: boolean = false;

    public subscription: SubscriptionEnum = SubscriptionEnum.Free;

    public lookupKey: LookupKeyEnum | null = null;

    public subscriptionEndDate: Date | null = null;

    public hadTrialing: boolean = false;

    public interestedInSubscription: LookupKeyEnum = LookupKeyEnum.PremiumMonthlyCzk;

    public freeSubscriptionAvailable: boolean = true;

    constructor() {
        makeAutoObservable(this, {});
    }

    static create(dto: UserProfileDto): UserProfileMobxDto {
        const mobxDto = new UserProfileMobxDto();
        return Object.assign(mobxDto, dto);
    }

    static createFromArray(dtos: UserProfileDto[]): UserProfileMobxDto[] {
        return dtos.map((dto) => UserProfileMobxDto.create(dto));
    }

    get isAdmin(): boolean {
        return this.role === UserRoleEnum.Admin;
    }
}
