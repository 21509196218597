import { makeAutoObservable, runInAction } from 'mobx';
import { BarDatum } from '@nivo/bar';
import moment from 'moment/moment';
import type { RootStore } from './root-store';
import { ProfitMobxDto } from '../mobx/dtos/profits/profit-mobx-dto';

export type ProfitsStoreHydration = {
    profits: ProfitMobxDto;
};

export class ProfitsStore {
    rootStore: RootStore;

    profits: ProfitMobxDto | null = null;

    loading = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    async fetchProfits(): Promise<void> {
        try {
            this.startLoading();
            const profits = await this.rootStore.loadWithDelay(() => {
                return this.rootStore.apiClient.profitController.profitControllerGetProfitsByMonths();
            });
            if (profits) {
                runInAction(() => {
                    this.profits = ProfitMobxDto.create(profits);
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            this.stopLoading();
        }
    }

    get chartData(): BarDatum[] {
        const chartData: BarDatum[] = [];

        if (!this.profits) {
            return [];
        }

        this.profits.years.forEach((year) => {
            year.months.forEach((month) => {
                const currentDate = moment()
                    .set('year', year.year)
                    .set('month', month.month - 1)
                    .startOf('month');

                chartData.push({
                    date: currentDate.format(process.env.NEXT_PUBLIC_API_DATE_FORMAT),
                    investedPlus: month.profitPerformance >= 0 ? month.profitPerformance : '',
                    investedMinus: month.profitPerformance < 0 ? month.profitPerformance : '',
                    investedPlusPercentage: month.profitPercentage >= 0 ? month.profitPercentage : '',
                    investedMinusPercentage: month.profitPercentage < 0 ? month.profitPercentage : '',
                });
            });
        });

        return chartData.slice(0);
    }

    get chartDataPercentage(): BarDatum[] {
        const chartData: BarDatum[] = [];

        if (!this.profits) {
            return [];
        }

        this.profits.years.forEach((year) => {
            year.months.forEach((month) => {
                const currentDate = moment()
                    .set('year', year.year)
                    .set('month', month.month - 1)
                    .startOf('month');

                chartData.push({
                    date: currentDate.format(process.env.NEXT_PUBLIC_API_DATE_FORMAT),
                    investedPlus: month.profitPercentage >= 0 ? month.profitPercentage : '',
                    investedMinus: month.profitPercentage < 0 ? month.profitPercentage : '',
                });
            });
        });

        return chartData.slice(0);
    }
}
