import { createModelSchema, identifier, list, object, primitive, reference } from 'serializr';
// import moment from 'moment';
import { Portfolio } from './entities/portfolio';
import { User } from './entities/user';
import { InvestmentsDto } from './entities/investments/investments-dto';
import { MonthDto } from './entities/investments/month-dto';
import { YearDto } from './entities/investments/year-dto';
import { ExchangeEntity } from './entities/exchange-entity';
import { ProfitDto } from './entities/profits/profit-dto';
import { ProfitMonthDto } from './entities/profits/profit-month-dto';
import { SymbolDetailDto } from './entities/profits/symbol-detail-dto';
import { ProfitYearDto } from './entities/profits/profit-year-dto';
// import { WaitingUser } from './entities/admin/user/waiting-user';
// import { ActiveUser } from './entities/admin/user/active-user';
// import { PortfolioListItem } from './entities/admin/portfolio/portfolio-list-item';
// import { AdminStatisticsDto } from './entities/admin/statistic/admin-statistics-dto';
// import { NotConfirmedUser } from './entities/admin/user/not-confirmed-user';
import { Currency } from './entities/currency';
// import { SymbolHistoryDto } from './entities/symbol-history-dto';
// import { WatchlistEntity } from './entities/watchlist-entity';
// import { Trade } from './entities/trade';
// import { CreatePortfolioDto } from './entities/create-portfolio-dto';
// import { CreateWatchlistDto } from './entities/create-watchlist-dto';
// import { UpdateTradeDto } from './entities/update-trade-dto';
// import { SymbolEntity } from './entities/symbol-entity';
// import { AreaChartData } from './entities/statistic/area-chart-data';
// import { AreaChart } from './entities/statistic/area-chart';
// import { PieChartData } from './entities/statistic/pie-chart-data';
// import { PieChart } from './entities/statistic/pie-chart';
// import { UnrealizedAllocation } from './entities/statistic/unrealized-allocation';
// import { UnrealizedAllocationsOverview } from './entities/statistic/unrealized-allocations-overview';
// import { UpdatePortfolioDto } from './entities/update-portfolio-dto';
// import { UpdateWatchlistDto } from './entities/update-watchlist-dto';
// import { Dividend } from './entities/dividend';
// import { DividendCalendar } from './entities/dividend-calendar';
// import { DividendCalendarMonth } from './entities/dividend-calendar-month';
// import { DividendGain } from './entities/statistic/tax-report/dividend-gain';
// import { TaxReport } from './entities/statistic/tax-report/tax-report';
// import { Transaction } from './entities/statistic/tax-report/transaction';
// import { UnrealizedTransaction } from './entities/statistic/tax-report/unrealized-transaction';
// import { TradeMatch } from './entities/statistic/tax-report/trade-match';
// import { RealizedTransactionsSummary } from './entities/statistic/tax-report/realized-transaction-summary';
// import { SymbolDividendCalendar } from './entities/symbol-dividend-calendar';
// import { SymbolValue } from './entities/sybol-value';
// import { SymbolDividendChart } from './entities/symbol-dividend-chart';
// import { SymbolDetailGeneral } from './entities/symbol-detail/symbol-detail-general';
// import { SymbolDetailHighlight } from './entities/symbol-detail/symbol-detail-highlight';
// import { SymbolDetailTechnicals } from './entities/symbol-detail/symbol-detail-technicals';
// import { SymbolRegionDto } from './entities/symbol-detail/symbol-detail-region';
// import { WatchlistSymbolEntity } from './entities/watchlist-symbol-entity';
// import { SymbolDetailInvestment } from './entities/symbol-detail/symbol-detail-investment';
// import { TradeOverview } from './entities/trade-overview';
// import { RealizedAllocation } from './entities/statistic/realized-allocation';
// import { TaxReport2 } from './entities/statistic/tax-report/tax-report-2';
// import { PortfolioTrade } from './entities/statistic/portfolio-trade';
// import { RealizedBuyTrade } from './entities/statistic/realized-buy-trade';
// import { RealizedReportTrade } from './entities/statistic/tax-report/realized-report-trade';
// import { UnrealizedReportTrade } from './entities/statistic/tax-report/unrealized-report-trade';
// import { RealizedDividendsSummary } from './entities/statistic/tax-report/realized-divideds-summary';
// import { TradeSummary } from './entities/statistic/tax-report/trade-summary';
// import { Overview } from './entities/statistic/overview';
// import { SymbolSplitDto } from './entities/symbol-split-dto';
// import { SymbolDividendDto } from './entities/symbol-dividend-dto';
// import { SymbolAlternativeDto } from './entities/symbol-alternative-dto';
// import { CreateSymbolAlternativeDto } from './entities/create-symbol-alternative-dto';
// import { PortfolioIntegrationDto } from './entities/portfolio-integration';

// const apiMoment = custom(
//     (value) => {
//         return moment(value).format(process.env.NEXT_PUBLIC_API_DATE_FORMAT);
//     },
//     (value) => {
//         return moment(value, process.env.NEXT_PUBLIC_API_DATE_FORMAT);
//     },
// );

// const apiDate = custom(
//     (value) => {
//         return moment(value).format(process.env.NEXT_PUBLIC_API_DATE_FORMAT);
//     },
//     (value) => {
//         return moment(value, process.env.NEXT_PUBLIC_API_DATE_FORMAT).toDate();
//     },
// );

// const apiDateTime = custom(
//     (value) => {
//         return moment(value).format(process.env.NEXT_PUBLIC_API_DATE_TIME_FORMAT);
//     },
//     (value) => {
//         return moment(value, process.env.NEXT_PUBLIC_API_DATE_TIME_FORMAT).toDate();
//     },
// );

createModelSchema(User, {
    id: identifier(),
    email: primitive(),
    isOnWaitingList: primitive(),
    fullName: primitive(),
    nickname: primitive(),
    role: primitive(),
    portfolios: list(reference(Portfolio)),
    layout: primitive(),
    theme: primitive(),
    newsletter: primitive(),
    subscription: primitive(),
    lookupKey: primitive(),
    subscriptionEndDate: primitive(),
    hadTrialing: primitive(),
    interestedInSubscription: primitive(),
    freeSubscriptionAvailable: primitive(),
});

// createModelSchema(WaitingUser, {
//     id: identifier(),
//     email: primitive(),
//     fullName: primitive(),
//     registrationDate: primitive(),
//     daysSinceRegistration: primitive(),
// });
//
// createModelSchema(ActiveUser, {
//     id: primitive(),
//     fullName: primitive(),
//     email: primitive(),
//     portfoliosCount: primitive(),
//     tradesCount: primitive(),
//     assetsCount: primitive(),
//     subscription: primitive(),
//     lastLoginDate: primitive(),
//     registrationDate: primitive(),
//     stripeCustomerId: primitive(),
// });
//
// createModelSchema(NotConfirmedUser, {
//     id: identifier(),
//     email: primitive(),
//     fullName: primitive(),
//     registrationDate: primitive(),
// });

createModelSchema(Portfolio, {
    id: identifier(),
    name: primitive(),
    iconName: primitive(),
    colorName: primitive(),
    currency: object(Currency),
    userId: primitive(),
    currencyImpactUnrealizedValue: primitive(),
    currencyImpactRealizedValue: primitive(),
    selected: primitive(),
    portfolioValue: primitive(),
    invested: primitive(),
    realizedPortfolioValue: primitive(),
    realizedInvested: primitive(),
    realizedProfit: primitive(),
    realizedProfitPercentage: primitive(),
    unrealizedProfit: primitive(),
    unrealizedProfitPercentage: primitive(),
    numberOfTrades: primitive(),
    numberOfAssets: primitive(),
    isRecalculating: primitive(),
    portfolioHistoryCurrencyCode: primitive(),
    processed: primitive(),
});

// createModelSchema(PortfolioListItem, {
//     portfolioId: identifier(),
//     portfolioName: primitive(),
//     userFullName: primitive(),
//     userEmail: primitive(),
//     portfolioCurrencyCode: primitive(),
//     lastUpdated: primitive(),
//     selected: primitive(),
//     tradesCount: primitive(),
//     historyCount: primitive(),
//     correctHistoryCount: primitive(),
//     calculationTimeSeconds: primitive(),
//     isRecalculating: primitive(),
// });

// createModelSchema(Trade, {
//     id: identifier(),
//     symbol: object(SymbolEntity),
//     date: apiMoment,
//     type: primitive(),
//     amount: primitive(),
//     unitPrice: primitive(),
//     fees: primitive(),
//     note: primitive(),
//     currency: object(Currency),
//     symbolCode: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     portfolioId: primitive(),
// });

// createModelSchema(TradeDto, {
//     date: apiDate,
//     type: primitive(),
//     amount: primitive(),
//     unitPrice: primitive(),
//     fees: primitive(),
//     note: primitive(),
//     currencyCode: primitive(),
//     symbolId: primitive(),
//     symbolCode: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     portfolioId: primitive(),
// });
//
// createModelSchema(TradeOverview, {
//     amount: primitive(),
//     value: primitive(),
//     fees: primitive(),
//     currency: primitive(),
// });
//
// createModelSchema(UpdateTradeDto, {
//     id: identifier(),
//     symbolId: primitive(),
//     date: apiMoment,
//     type: primitive(),
//     amount: primitive(),
//     unitPrice: primitive(),
//     fees: primitive(),
//     note: primitive(),
//     currencyCode: primitive(),
//     portfolioId: primitive(),
// });

createModelSchema(Currency, {
    code: primitive(),
});

// createModelSchema(AdminStatisticsDto, {
//     enteredDate: primitive(),
//     count: primitive(),
//     logged: primitive(),
//     canceled: primitive(),
//     premium: primitive(),
// });

createModelSchema(ExchangeEntity, {
    id: identifier(),
    name: primitive(),
    region: primitive(),
    longName: primitive(),
    suffix: primitive(),
});

createModelSchema(InvestmentsDto, {
    years: list(object(YearDto)),
    numberOfMonths: primitive(),
    totalAmountWithoutSell: primitive(),
    totalAmountWithSell: primitive(),
    averageAmountWithoutSell: primitive(),
    averageAmountWithSell: primitive(),
});

createModelSchema(YearDto, {
    year: primitive(),
    months: list(object(MonthDto)),
});

createModelSchema(MonthDto, {
    month: primitive(),
    amountWithoutSell: primitive(),
    amountWithSell: primitive(),
});

createModelSchema(ProfitDto, {
    years: list(object(ProfitYearDto)),
    numberOfMonths: primitive(),
    totalProfit: primitive(),
    averageProfit: primitive(),
});

createModelSchema(ProfitYearDto, {
    year: primitive(),
    months: list(object(ProfitMonthDto)),
    total: object(ProfitMonthDto),
});

createModelSchema(ProfitMonthDto, {
    month: primitive(),
    profitPercentage: primitive(),
    profitPerformance: primitive(),
    profitAbsolute: primitive(),
    bestEarner: object(SymbolDetailDto),
    worstEarner: object(SymbolDetailDto),
});

createModelSchema(SymbolDetailDto, {
    symbol: primitive(),
    valueChangePercentage: primitive(),
    valueChangeAbsolute: primitive(),
});

// createModelSchema(PortfolioIntegrationDto, {
//     trading212Token: primitive(),
//     trading212Error: primitive(),
// });
//
// createModelSchema(Trade, {
//     id: identifier(),
//     symbol: object(SymbolEntity),
//     date: apiMoment,
//     type: primitive(),
//     amount: primitive(),
//     unitPrice: primitive(),
//     fees: primitive(),
//     note: primitive(),
//     currency: object(Currency),
//     symbolCode: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     portfolioId: primitive(),
// });
//
// createModelSchema(TradeDto, {
//     date: apiDate,
//     type: primitive(),
//     amount: primitive(),
//     unitPrice: primitive(),
//     fees: primitive(),
//     note: primitive(),
//     currencyCode: primitive(),
//     symbolId: primitive(),
//     symbolCode: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     portfolioId: primitive(),
// });
//
// createModelSchema(TradeOverview, {
//     amount: primitive(),
//     value: primitive(),
//     fees: primitive(),
//     currency: primitive(),
// });
//
// createModelSchema(UpdateTradeDto, {
//     id: identifier(),
//     symbolId: primitive(),
//     date: apiMoment,
//     type: primitive(),
//     amount: primitive(),
//     unitPrice: primitive(),
//     fees: primitive(),
//     note: primitive(),
//     currencyCode: primitive(),
//     portfolioId: primitive(),
// });
//
// createModelSchema(CreatePortfolioDto, {
//     id: identifier(),
//     name: primitive(),
//     iconName: primitive(),
//     colorName: primitive(),
//     currencyCode: primitive(),
// });
//
// createModelSchema(UpdatePortfolioDto, {
//     id: identifier(),
//     name: primitive(),
//     iconName: primitive(),
//     colorName: primitive(),
//     currencyCode: primitive(),
// });
//
// createModelSchema(SymbolEntity, {
//     id: identifier(),
//     symbol: identifier(),
//     name: primitive(),
//     exchange: object(ExchangeEntity),
//     type: primitive(),
//     iexId: primitive(),
//     figi: primitive(),
//     isin: primitive(),
//     currency: primitive(),
//     close: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     marketCapitalization: primitive(),
// });
//
// createModelSchema(WatchlistSymbolEntity, {
//     id: identifier(),
//     symbol: identifier(),
//     name: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     currency: primitive(),
//     marketCapitalization: primitive(),
// });
//
// createModelSchema(Overview, {
//     dateFrom: primitive(),
//     lastUpdated: primitive(),
//     portfolioValue: primitive(),
//     invested: primitive(),
//     investmentPeriod: primitive(),
//     averageInvestment: primitive(),
//     unrealizedProfit: primitive(),
//     unrealizedProfitPercentage: primitive(),
//     todayUnrealizedProfit: primitive(),
//     todayUnrealizedProfitPercentage: primitive(),
//     realizedProfit: primitive(),
//     realizedProfitPercentage: primitive(),
//     dividends: primitive(),
//     dividendsNextYearPrediction: primitive(),
//     dividendsYield: primitive(),
//     dividendsYieldOnCost: primitive(),
//     fees: primitive(),
//     feesPercentage: primitive(),
//     rateImpact: primitive(),
//     totalPortfolioValue: primitive(),
//     isRecalculating: primitive(),
// });
//
// createModelSchema(AreaChartData, {
//     x: date(),
//     y: primitive(),
// });
//
// createModelSchema(AreaChart, {
//     id: identifier(),
//     data: list(object(AreaChartData)),
// });
//
// createModelSchema(PieChartData, {
//     id: identifier(),
//     name: primitive(),
//     value: primitive(),
//     absoluteValue: primitive(),
// });
//
// createModelSchema(PieChart, {
//     data: list(object(PieChartData)),
// });
//
// createModelSchema(UnrealizedAllocation, {
//     symbol: primitive(),
//     symbolName: primitive(),
//     symbolCurrency: primitive(),
//     symbolId: primitive(),
//     exchangeId: primitive(),
//     type: primitive(),
//     invested: primitive(),
//     averageInvested: primitive(),
//     value: primitive(),
//     averageValue: primitive(),
//     currentUnitPrice: primitive(),
//     currency: primitive(),
//     amount: primitive(),
//     weight: primitive(),
//     color: primitive(),
//     profitPercentage: primitive(),
//     profitValue: primitive(),
//     currencyImpactValue: primitive(),
//     currencyImpactPercentage: primitive(),
// });
//
// createModelSchema(RealizedAllocation, {
//     averageSellUnitPrice: primitive(),
//     symbol: primitive(),
//     symbolName: primitive(),
//     symbolCurrency: primitive(),
//     symbolId: primitive(),
//     exchangeId: primitive(),
//     type: primitive(),
//     invested: primitive(),
//     averageInvested: primitive(),
//     value: primitive(),
//     averageValue: primitive(),
//     currency: primitive(),
//     amount: primitive(),
//     weight: primitive(),
//     color: primitive(),
//     profitPercentage: primitive(),
//     profitValue: primitive(),
//     currencyImpactValue: primitive(),
//     currencyImpactPercentage: primitive(),
// });
//
// createModelSchema(UnrealizedAllocationsOverview, {
//     invested: primitive(),
//     value: primitive(),
//     currencyImpactValue: primitive(),
//     profit: primitive(),
//     currency: primitive(),
// });
//
// createModelSchema(Dividend, {
//     symbolId: primitive(),
//     symbolCode: primitive(),
//     symbolCurrency: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     exchange: primitive(),
//
//     currency: primitive(),
//     recordDate: primitive(),
//     paymentDate: primitive(),
//     date: primitive(),
//
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     region: primitive(),
//
//     quantity: primitive(),
//     amount: primitive(),
// });
//
// createModelSchema(SymbolHistoryDto, {
//     symbol: primitive(),
//     date: primitive(),
//     close: primitive(),
//     open: primitive(),
//     high: primitive(),
//     low: primitive(),
//     volume: primitive(),
// });
//
// createModelSchema(DividendCalendarMonth, {
//     month: primitive(),
//     amount: primitive(),
//     isFutureDividend: primitive(),
// });
//
// createModelSchema(DividendCalendar, {
//     year: primitive(),
//     months: list(object(DividendCalendarMonth)),
// });
//
// createModelSchema(SymbolDetailInvestment, {
//     investmentValue: primitive(),
//     amount: primitive(),
//     value: primitive(),
//     averageBuyPriceInSymbolCurrency: primitive(),
//     averageBuyPrice: primitive(),
//     profit: primitive(),
//     profitPercentage: primitive(),
// });
//
// createModelSchema(SymbolRegionDto, {
//     name: identifier(),
//     equity: primitive(),
// });
//
// createModelSchema(SymbolSplitDto, {
//     date: primitive(),
//     ratio: primitive(),
//     ratioValue: primitive(),
// });
//
// createModelSchema(SymbolDividendDto, {
//     recordDate: primitive(),
//     paymentDate: primitive(),
//     date: primitive(),
//     currency: primitive(),
//     quantity: primitive(),
//     amount: primitive(),
//     amountInSymbolCurrency: primitive(),
//     amountPerUnit: primitive(),
//     amountPerUnitInSymbolCurrency: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     symbolId: primitive(),
//     symbolCode: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     symbolCurrency: primitive(),
//     region: primitive(),
// });
//
// createModelSchema(SymbolDetailGeneral, {
//     id: identifier(),
//     type: primitive(),
//     description: primitive(),
//     sector: primitive(),
//     industry: primitive(),
//     symbol: object(SymbolEntity),
//     regions: list(object(SymbolRegionDto)),
// });
//
// createModelSchema(SymbolDetailHighlight, {
//     id: identifier(),
//     marketCapitalization: primitive(),
//     peRatio: primitive(),
//     ebitda: primitive(),
//     epsEstimateCurrentYear: primitive(),
//     dividendYield: primitive(),
//     dividendPayoutRatio: primitive(),
//     dividend: primitive(),
//     margin: primitive(),
//     marginTTM: primitive(),
//     eps: primitive(),
//     roe: primitive(),
//     evEbitda: primitive(),
//     dividendYears: primitive(),
//     continuousDividendYears: primitive(),
// });
//
// createModelSchema(SymbolDetailTechnicals, {
//     id: identifier(),
//     beta: primitive(),
// });
//
// createModelSchema(DividendGain, {
//     dividendsAbsolute: primitive(),
//     dividendsPercentage: primitive(),
// });
//
// createModelSchema(TradeMatch, {
//     buyTrade: object(Trade),
//     sellTrade: object(Trade),
//     tradeSummary: object(TradeSummary),
//     holdingPeriodDays: primitive(),
// });
//
// createModelSchema(TradeSummary, {
//     quantity: primitive(),
//     overallValue: primitive(),
//     overallGain: primitive(),
//     fees: primitive(),
// });
//
// createModelSchema(RealizedTransactionsSummary, {
//     quantity: primitive(),
//     overallValue: primitive(),
//     overallGain: primitive(),
//     limitPercentage: primitive(),
//     fees: primitive(),
//     limit: primitive(),
//     overallValueInCzk: primitive(),
//     overallGainInCzk: primitive(),
//     overallFeesInCzk: primitive(),
// });
//
// createModelSchema(RealizedDividendsSummary, {
//     quantity: primitive(),
//     overallValue: primitive(),
//     overallForeignValue: primitive(),
//     limitPercentage: primitive(),
//     limit: primitive(),
//     overallValueInCzk: primitive(),
//     overallForeignValueInCzk: primitive(),
// });
//
// createModelSchema(UnrealizedTransaction, {
//     buyTrade: object(Trade),
//     holdingPeriodDays: primitive(),
//     currentMarketValue: primitive(),
//     netGainLoss: primitive(),
// });
//
// createModelSchema(Transaction, {
//     trades: list(object(TradeMatch)),
// });
//
// createModelSchema(TaxReport, {
//     year: primitive(),
//     realizedTransactions: list(object(Transaction)),
//     realizedTransactionsSummary: object(RealizedTransactionsSummary),
//     unrealizedTransactions: list(object(UnrealizedTransaction)),
//     dividends: list(object(Dividend)),
//     dividendsSummary: object(RealizedDividendsSummary),
// });
//
// const portfolioTradeProps = {
//     unitPrice: primitive(),
//     symbolId: primitive(),
//     symbol: primitive(),
//     symbolName: primitive(),
//     symbolCurrency: primitive(),
//     symbolRate: primitive(),
//     tradeCurrency: primitive(),
//     portfolioCurrency: primitive(),
//     date: primitive(),
//     amount: primitive(),
//     totalPricePortfolioCurrency: primitive(),
//     symbolType: primitive(),
//     type: primitive(),
//     tradeSymbolId: primitive(),
//     exchange: primitive(),
//     fees: primitive(),
//     feesPortfolioCurrency: primitive(),
//     rawAmount: primitive(),
//     note: primitive(),
// };
//
// createModelSchema(PortfolioTrade, portfolioTradeProps);
// createModelSchema(RealizedBuyTrade, {
//     ...portfolioTradeProps,
//     sellDate: primitive(),
//     sellTotalPricePortfolioCurrency: primitive(),
//     sellFeesPortfolioCurrency: primitive(),
// });
//
// createModelSchema(RealizedReportTrade, {
//     trade: object(RealizedBuyTrade),
//     holdingPeriodDays: primitive(),
//     profitLoss: primitive(),
// });
// createModelSchema(UnrealizedReportTrade, {
//     trade: object(PortfolioTrade),
//     holdingPeriodDays: primitive(),
//     profitLoss: primitive(),
//     marketValue: primitive(),
// });
//
// createModelSchema(TaxReport2, {
//     year: primitive(),
//     realizedReportTrades: list(object(RealizedReportTrade)),
//     realizedTransactionsSummary: object(RealizedTransactionsSummary),
//     unrealizedReportTrades: list(object(UnrealizedReportTrade)),
//     dividends: list(object(Dividend)),
//     dividendsSummary: object(RealizedDividendsSummary),
// });
//
// createModelSchema(SymbolValue, {
//     symbol: primitive(),
//     value: primitive(),
// });
//
// createModelSchema(SymbolDividendChart, {
//     timePeriod: primitive(),
//     symbols: list(object(SymbolValue)),
// });
//
// createModelSchema(SymbolDividendCalendar, {
//     symbol: primitive(),
//     symbolName: primitive(),
//     symbolType: primitive(),
//     symbolCurrency: primitive(),
//     exchangeId: primitive(),
//     exchangeName: primitive(),
//     months: list(object(DividendCalendarMonth)),
// });
//
// createModelSchema(WatchlistEntity, {
//     id: identifier(),
//     name: primitive(),
//     iconName: primitive(),
//     colorName: primitive(),
// });
//
// createModelSchema(CreateWatchlistDto, {
//     id: identifier(),
//     name: primitive(),
//     iconName: primitive(),
//     colorName: primitive(),
// });
//
// createModelSchema(UpdateWatchlistDto, {
//     id: identifier(),
//     name: primitive(),
//     iconName: primitive(),
//     colorName: primitive(),
// });
//
// createModelSchema(SymbolAlternativeDto, {
//     id: identifier(),
//     alternativeSymbol: primitive(),
//     alternativeName: primitive(),
//     symbol: primitive(),
//     name: primitive(),
//     validFrom: date(),
// });
//
// createModelSchema(CreateSymbolAlternativeDto, {
//     alternativeSymbol: primitive(),
//     alternativeName: primitive(),
//     symbol: object(SymbolEntity),
//     validFrom: date(),
// });
